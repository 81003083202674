 <script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { required, url } from "vuelidate/lib/validators";
import EmptyList from "@/components/widgets/empty_list"
import helpers from '../../../helpers'
import Multiselect from "vue-multiselect";

import {
  personalShopperMethods,
  goalMethods
} from "@/state/helpers";

/**
 * Edit campaign Component
 */
export default {
	page: {
		title: "Personal Shopper",
		meta: [
			{
				name: "description",
				content: appConfig.description,
			},
		],
	},
	components: {
		Layout,
		PageHeader,
    EmptyList,
    Multiselect
	},
	data() {
		return {
			title: "",
			items: [],
			isLoading: false,
      submitted: false,
      submittedSource: false,
			ps: {},
      showNewSourceModal:false,
      auxSource:{},
      editIndex: -1,
      editSource: false,
      empty_sources_config:{
          "title" : this.$t('personal_shoppers.empty_sources_title'),
          "subtitle": this.$t('personal_shoppers.empty_sources_subtitle'),
          "buttonText" : this.$t('personal_shoppers.new_source'),
          icon: "fa-cubes"
      },
      useItemsSource: true,
      useRecommendations: false,
      goals: [
      { _id: 'none',name : this.$t('goals.none')},
        { _id: 'purchase',name : this.$t('goals.purchase')},
        { _id: 'cart',name : this.$t('goals.cart')},
        { _id: 'like',name : this.$t('goals.like')},
        { _id: 'view',name : this.$t('goals.view')},
      ],
      auxGoal: { _id: 'purchase',name : this.$t('goals.purchase')},
      goal_times : [
        {id: 'hours', label:'Hours'},
        {id: 'days', label:'Days'},
      ],
      auxGoalTime:{id: 'days', label:'Days'},
      auxDay: 1,
      auxHour: 0,
      timeError: false
    }
  },
  validations: {
    ps: {
      name: {
        required,
      },
      description: {
        required,
      },
    },
    auxSource :{
      name: {
        required,
      },
      value: {
        required,
        url
      },
    }
  },
	mounted() {
		
    const psId = this.$route.query.id;
		
		if(psId){
      this.loadCustomGoals(true);
      let loader = this.$loading.show();
      this.title = this.$t('personal_shoppers.edit_personal_shopper');
      this.getPersonalShopper(psId).then((res)=>{
        this.ps = res.data;
        this.useItemsSource = this.ps.sources?.filter(s=> s.type =='item').length > 0;
        this.useRecommendations = this.ps.flags?.use_recommendations;

        if(this.ps.goal){
          this.auxGoal = this.goals.filter(g=>g._id == this.ps.goal._id).length > 0 ? this.goals.filter(g=>g._id == this.ps.goal._id)[0] : this.auxGoal;
          if(this.ps.goal.time){
            this.auxDay = Math.floor(this.ps.goal.time / 24)
            this.auxHour = this.ps.goal.time % 24
          }
        }else{
          this.ps.goal = {
            _id : 'purchase',
            time: 24
          }
        }
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
			
		}else{
      this.$router.push({
            path: `/404`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    }
		
	},
	created() {
		
	},
  computed: {
   
  },
	methods: {
    ...personalShopperMethods,
    ...helpers,
    ...goalMethods,

    onTimeChanged(){
      if(!this.auxDay)
        this.auxDay = 1;

      if(!this.auxHour)
        this.auxHour = 0;

      this.ps.goal.time = this.auxDay * 24 + this.auxHour;
      this.timeError = this.ps.goal.time > (90 * 24)
    },

    onGoalSelected(){
      if(!this.ps.goal)
        this.ps.goal = {}

      this.ps.goal._id = this.auxGoal._id; 
    },

    loadCustomGoals(isUpdate){
      const params={
        q: `project=${localStorage.getItem('current_project')}&limit=50`,
      }
      this.getGoals(params).then((res)=>{
        this.goals = this.goals.concat(res.data);
        if(isUpdate){
          this.auxGoal = this.goals.filter(g=>g._id == this.ps.goal?._id).length > 0 ? this.goals.filter(g=>g._id == this.ps.goal?._id)[0] : this.auxGoal;
        }
      });
    },

    onConfirmAddSourceClicked(){
      this.submittedSource = true;

      this.$v.$touch();

			if (this.$v.auxSource.$invalid) {
				return;
			}

      if(!this.ps.sources)
        this.ps.sources = [];
      
      if(this.editSource){
        this.$set(this.ps.sources, this.editIndex, this.auxSource);
      }else{
        this.ps.sources.push(this.auxSource);
      }
      
      this.showNewSourceModal = false;
    },

    onNewSourceClicked(){
      this.editSource = false
      this.submittedSource = false;
      this.auxSource = {
        type: 'url'
      }
      this.showNewSourceModal = true;
    },
    
    onCancelClicked(){
      this.$router.push({
            path: `/personal-shoppers`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    },
    onConfirmClicked(){
      this.submitted = true;

      this.$v.$touch();

			if (this.$v.ps.$invalid) {
				return;
			}

      let loader = this.$loading.show();

      let payload = Object.assign({}, this.ps);
      
      this.updatePersonalShopper(payload).then(()=>{
        this.$notify({ type: 'success', text: this.$t("personal_shoppers.update_personal_shopper_success"),title:  this.$t("personal_shoppers.title") });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('personal_shoppers.update_personal_shopper_error'),title:  this.$t('personal_shoppers.title') });
      }).finally(()=>{
        loader.hide();
      })

    },
    
    onEditSourceClicked(data, i){
      this.auxSource = Object.assign({}, data);
      this.showNewSourceModal = true;
      this.editSource = true;
      this.editIndex = i;
    },
    onRemoveSourceClicked(index){
      if(this.ps.sources[index].type == 'item'){
        this.useItemsSource = false;
      }
      this.ps.sources.splice(index,1);
    },
    onTrainClicked(){
      const loader = this.$loading.show();
      
      this.trainPersonalShopper(this.ps._id).then(()=>{
        this.$notify({ type: 'success', text: this.$t("personal_shoppers.train_personal_shopper_success"),title:  this.$t("personal_shoppers.title") });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('personal_shoppers.train_personal_shopper_error'),title:  this.$t('personal_shoppers.title') });
      }).finally(()=>{
        loader.hide();
      })
    },
    onLinkClicked(data){
      window.open(data.url, '_blank')
    },
    onUseRecommendationsChanged(){
      if(this.useRecommendations){
        this.ps.flags.use_recommendations = true;
      }else{
        this.ps.flags.use_recommendations = false;
      }
    },
    onUseItemsChanged(){
      if(!this.ps.sources)
        this.ps.sources = [];

      if(this.useItemsSource){
        this.ps.sources.unshift({
          name: 'Items',
          type: 'item',
          value:true
        });
      }else{
        this.ps.sources = this.ps.sources.filter(s=>s.type!='item');
      }
    } 
  }
	
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
    <div class="text-end">
      <p class="mb-2">{{ `${$t('personal_shoppers.last_sync')}: ${ps.lastSync ? `${parseDateWithLocale(ps.lastSync, {year: 'numeric', month: 'short', day: 'numeric'})} ${parseTimeWithLocale(ps.lastSync, {hour: '2-digit', minute:'2-digit'})}` : '-'}`  }}</p>
    </div>
		<div class="checkout-tabs">
			<b-tabs
				pills
				vertical
				nav-class="p-0"
				nav-wrapper-class="col-xl-2 col-sm-3">
				<b-tab active>
					<template v-slot:title>
						<i class="bx bxs-briefcase-alt d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">{{$t('personal_shoppers.basic_info')}}</p>
					</template>
					<b-card-text>
						<div class="card">
							<div class="card-body">
								<h4 class="card-title">{{$t('personal_shoppers.basic_info')}}</h4>
								<p class="card-title-desc">{{$t('personal_shoppers.basic_info_subtitle')}}</p>
								<div class="row">
									<div class="col">
										<div class="mb-3">
											<label for="campaignName">{{$t('personal_shoppers.name')}}*</label>
											<input
												id="campaignName"
												v-model="ps.name"
												name="name"
												type="text"
                        :placeholder="$t('personal_shoppers.name_placeholder')"
												class="form-control"
												:class="{'is-invalid': submitted && $v.ps.name.$error,}"
											/>
											<div v-if="submitted && !$v.ps.name.required" class="invalid-feedback">
                        {{$t('personal_shoppers.name_required')}}
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<div class="mb-3">
											<label >{{$t('personal_shoppers.description')}}*</label>
											<input
												v-model="ps.description"
												type="text"
                        :placeholder="$t('personal_shoppers.description_placeholder')"
												class="form-control"
												:class="{'is-invalid': submitted && $v.ps.description.$error,}"
											/>
											<div v-if="submitted && !$v.ps.description.required" class="invalid-feedback">
                        {{$t('personal_shoppers.description_required')}}
											</div>
										</div>
									</div>
								</div>
                <div class="row">
									<div class="col">
										<div class="mb-3">
											<label >{{$t('personal_shoppers.instructions')}}</label>
											<textarea
												v-model="ps.instructions"
												type="textarea"
                        rows="3"
                        :placeholder="$t('personal_shoppers.instructions_placeholder')"
												class="form-control"
											/>
										</div>
									</div>
								</div>
                <div class="row mb-3">
                  <div class="col-sm-6">
                  <div class="mb-0">
                      <label for="promotionName">{{$t('goals.goal')}}*</label>
                      <div class="">
                        <multiselect
                          :options="goals" 
                          v-model="auxGoal"
                          track-by="_id" 
                          label="name"
                          selectLabel=""
                          deselectLabel=""
                          :allowEmpty="false"
                          class="me-2"
                          @input="onGoalSelected"
                          :show-labels="false"
                        />
                      </div>
                      <div class="d-inline-flex align-items-baseline mt-1" v-if="auxGoal?._id != 'none'">
                        {{$t('goals.goal_next')}} 
                        <b-form-input class="form-control form-control-sm ms-2 me-2" type="number" v-model="auxDay" style="width: 60px;" @input="onTimeChanged"></b-form-input>
                        {{$t('goals.goal_days')}}
                        <b-form-input class="form-control form-control-sm ms-2 me-2" type="number" v-model="auxHour" style="width: 60px;" @input="onTimeChanged"></b-form-input>
                        {{$t('goals.goal_hours')}} 
                      </div>
                      <div v-if="submitted && timeError" class="invalid-feedback" style="display: block;">
                        {{ $t('goals.goal_time_error') }}
                      </div>
                  </div>
                </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-6">
                    <div class="mb-3">
                      <b-form-checkbox
                          v-model="useItemsSource"
                          class="mb-3"
                          plain
                          @change="onUseItemsChanged">
                          {{ $t('personal_shoppers.use_items') }}
                      </b-form-checkbox>
                    </div>
                  </div>
                </div> 
                <div class="row">
                  <div class="col-sm-6">
                    <div class="mb-3">
                      <b-form-checkbox
                          v-model="useRecommendations"
                          class="mb-3"
                          plain
                          @change="onUseRecommendationsChanged">
                          {{ $t('personal_shoppers.use_recommendations') }}
                      </b-form-checkbox>
                    </div>
                  </div>
                </div> 
              </div>
						</div>
					</b-card-text>
				</b-tab>
				<b-tab>
					<template v-slot:title>
						<i class="bx bx-bulb d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">{{$t('personal_shoppers.sources_title')}}</p>
					</template>
					<div class="card">
						<div class="card-body">
							<h4 class="card-title">{{$t('personal_shoppers.sources_title')}}</h4>
							<p class="card-title-desc mb-0">{{$t('personal_shoppers.sources_subtitle')}}</p>
              <div class="text-end col" v-if="ps.sources?.length > 0">
								<button type="button" v-on:click="onNewSourceClicked" class="btn btn-sm btn-primary mt-0 mb-3" >+ {{$t('personal_shoppers.new_source')}}</button>
							</div>
              <EmptyList :config="empty_sources_config" v-if="ps.sources?.length == 0" @onButtonClicked="onNewSourceClicked" class="mt-5 mb-3 d-block"/>
              <table  id="my-table" class="table align-middle table-nowrap table-hover"  v-if="ps.sources?.length > 0">
                <thead class="table-light">
                  <tr>
                    <th scope="col">{{$t("personal_shoppers.name")}}</th>
                    <th scope="col">{{$t("personal_shoppers.last_sync")}}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                  <tbody>
                    <tr v-for="(source,index) in ps.sources" :key="index">
                      <td>{{source.name}}</td>
                      <td>{{ source.sync_date ? `${parseDateWithLocale(source.sync_date, {year: 'numeric', month: 'short', day: 'numeric'})} ${parseTimeWithLocale(source.sync_date, {hour: '2-digit', minute:'2-digit'})}` : '-'}}</td>
                      <td class="text-end"> 
                        <i class= 'fas fa-link interact text-primary me-2' v-if="source.type=='url'" v-on:click="onLinkClicked(data)" />
                        <i class= 'fas fa-edit interact text-success me-2' v-if="source.type!='item'" v-on:click="onEditSourceClicked(source,index)" />
                        <i class= 'fas fa-trash-alt interact text-danger me-2' v-on:click="onRemoveSourceClicked(index)" /> 
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
					</div>
				</b-tab>
          <b-tab>
					<template v-slot:title>
						<i class="bx bxs-wrench d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">{{$t('personal_shoppers.settings')}}</p>
					</template>
					<div class="card">
						<div class="card-body">
							<h4 class="card-title">{{$t('personal_shoppers.settings')}}</h4>
							<p class="card-title-desc">{{$t('personal_shoppers.settings_subtitle')}}</p>
              <p v-if="!ps.settings || Object.keys(ps.settings).length == 0" class="text-center mt-5"> {{ $t('personal_shoppers.no_settings') }}</p>
              <div v-if="ps.settings && Object.keys(ps.settings).length > 0" class="mt-5">
                <div class="row" v-for="key in Object.keys(ps.settings)" :key="key">
                  <b-form-input class="col me-2" disabled :value="key"></b-form-input>
                  <b-form-input class="col ms-2" disabled :value="ps.settings[key]"></b-form-input>
                </div>
              </div>
						</div>
					</div>
				</b-tab>
      </b-tabs>
		</div>
    <b-modal v-model="showNewSourceModal" :title=" editSource? $t('personal_shoppers.edit_source') : $t('personal_shoppers.new_source')" @ok.prevent="onConfirmAddSourceClicked" :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
      <div>
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label for="role">{{$t('personal_shoppers.name')}}*</label>
              <b-form-input 
                type="text" 
                v-model="auxSource.name" 
                :class="{'is-invalid': submittedSource && $v.auxSource.name.$error,}"
                :placeholder="$t('personal_shoppers.name_placeholder')"
                />
              <div v-if="submittedSource && !$v.auxSource.name.required" class="invalid-feedback">
                {{$t('personal_shoppers.name_required')}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label for="role">URL*</label>
              <b-form-input 
                type="url" 
                v-model="auxSource.value" 
                :class="{'is-invalid': submittedSource && $v.auxSource.value.$error,}"
                :placeholder="$t('personal_shoppers.url_placeholder')"
              />
              <div v-if="submittedSource && !$v.auxSource.value.required" class="invalid-feedback">
                {{$t('personal_shoppers.value_required')}}
              </div>
              <div v-if="submittedSource && !$v.auxSource.value.url" class="invalid-feedback">
                {{$t('personal_shoppers.value_url')}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
		<div class="row mt-4">
			<div class="col-sm-6">
			</div>
			<!-- end col -->
			<div class="col-sm-6">
				<div class="text-end">
					<b-button variant="light" class="w-md mb-3" @click="onCancelClicked">{{$t('common.cancel')}}</b-button>
          <button class="btn btn-outline-primary w-md mb-3 ms-2" @click="onTrainClicked">{{$t('personal_shoppers.train')}}</button>
					<b-button variant="primary" class="w-md ms-2 mb-3" @click="onConfirmClicked">{{$t('common.confirm')}}</b-button>
				</div>
			</div>
			<!-- end col -->
		</div>
	</Layout>
</template>

<style scoped>

.edit-picture-button {
	height: 1.5rem !important;
	width: 1.5rem !important;
	top: -5px;
	position: absolute;
	color:#adb5bd
}

.icon-color-grey {
	color:#adb5bd
}

</style>